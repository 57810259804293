<script setup lang="ts">
useHead({
  bodyAttrs: {
    id: "promo",
  },
  script: [
    {
      src: 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.2/jquery.min.js'
    },
    {
      src: 'https://fire.bang.co.jp/assets/javascripts/alertify.min.js'
    },
    {
      src: 'https://fire.bang.co.jp/assets/javascripts/alertify_custom.js'
    }
  ],
  link: [
    {
      rel: 'stylesheet',
      href: 'https://fire.bang.co.jp/assets/stylesheets/alertify.min.css'
    },
    {
      rel: 'stylesheet',
      href: 'https://fire.bang.co.jp/assets/stylesheets/alertify_custom.css'
    }
  ]
});
</script>

<template>
  <div>
    <slot />
  </div>
</template>
